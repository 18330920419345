#mega-menu-wrap-primary_navigation #mega-menu-primary_navigation {
	text-align: right !important;
	margin-top: -40px !important;
	float: left !important;
	width: 100% !important;
}
.mega-sub-menu {
	border-left:1px solid $brand-primary;
	border-right:1px solid $brand-primary;
	border-bottom:1px solid $brand-primary;
}
#mega-menu-wrap-primary_navigation #mega-menu-primary_navigation li.mega-menu-megamenu ul.mega-sub-menu li.mega-menu-item ul.mega-sub-menu li.mega-menu-item {
	font-weight:normal !important;
}
#mega-menu-wrap-primary_navigation #mega-menu-primary_navigation li.mega-menu-megamenu ul.mega-sub-menu {
	padding: 10px 0px 10px 0px !important;
}
#mega-menu-wrap-primary_navigation #mega-menu-primary_navigation li.mega-menu-megamenu ul.mega-sub-menu li.mega-menu-item  ul.mega-sub-menu li.mega-menu-item a.mega-menu-link {
	color:$brand-primary;
	font-size:13px !important;
	font-weight:normal !important;
	padding: 5px 5px 5px 15px !important;
}

#mega-menu-wrap-primary_navigation #mega-menu-primary_navigation li.mega-menu-megamenu ul.mega-sub-menu li.mega-menu-item ul.mega-sub-menu li.mega-menu-item:hover {
	background:$brand-primary;
	color:$white;
}
#mega-menu-wrap-primary_navigation #mega-menu-primary_navigation li.mega-menu-megamenu ul.mega-sub-menu li.mega-menu-item ul.mega-sub-menu li.mega-menu-item a.mega-menu-link:hover,
#mega-menu-wrap-primary_navigation #mega-menu-primary_navigation > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-item li.mega-menu-item > a.mega-menu-link:hover,
 {
	color:$white;
}
#mega-menu-wrap-primary_navigation #mega-menu-primary_navigation > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-item > a.mega-menu-link {
	color: $brand-highlight;
}
#mega-menu-wrap-primary_navigation #mega-menu-primary_navigation > li.mega-menu-item > a.mega-menu-link:hover {
	background:none !important;
	color: $brand-highlight;
}


.top-banner{
	float: left;
	width: 100%;
	background: $gray-darker;
}
.navbar-brand {
	height: 100%;
}
.navbar-nav {
	float: right;
}
.navbar-default {
	background-color: $brand-primary;
	border-color: none;
	margin-bottom: 0;
}
.navbar-default .navbar-nav>li>a {
	color: $white;
}
.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover {
	color: $white;
	background-color: none;
}
.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover {
	border-bottom: $brand-highlight 3px solid;
}
.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover, .navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>a:hover {
	color: $brand-highlight
}

@media (min-width: 768px) {
	.top-banner {
			.navbar-nav>li>a {
				padding-top: 5px;
				padding-bottom: 5px;
		}
	}
	.navbar-nav>li>a {
			padding-top: 35px;
			padding-bottom: 35px;
			color:$white;
	}
}
#header-image {
	background: rgba(0, 0, 0, 0.7); 
}
.page-header {
    padding: 125px 0 20px;
    border-bottom: 1px solid $gray-lighter;
    margin:0 0 20px 0;
    color: $gray-darkest;
}
.grayscale h1{
    background: $brand-primary;
    float: left;
    padding: 10px 20px 10px 10px;
    color: $white;
}