/*************************
Regions
*************************/
.regions {
	background:$blue;
	color:$white;
	padding:2em 0;
	
		h4 {
			color:$orange;
			font-size: 25px;
			padding: 3px 0;
			margin: 0;
		}
		
		ul {
			padding:0;
			
				li {
					list-style:none;
					margin-left: 5px;
				
				&:hover {
					list-style-image:url('/securitycompanies.net/wp-content/themes/sec-com/dist/images/map-icon.png');
					
					a {
						color:$orange;
					}	
				}
				a {
					color:$white;
					display: block;
				}

			}
		}
		
		.regions-title {
			padding: 15px 0;
			
			img {
				width: 30px;
				float:left;
				margin-right:10px;
			}
		}
}
.sponsor-wrap {
	background:$gray-lighter;
	color:$black;
	padding:25px 0;
		
		img {
			-webkit-filter: grayscale(80%); /* Safari */
			filter: grayscale(100%);
			max-width:100%;
		}
		
		a {
			color:$blue;
			
			&hover {
				color:$orange;
			}
		}
		ul.sponsors{
			width: 100%;
			margin-bottom: 20px;
			overflow: hidden;
		}
		li.sponsor{
			line-height: 135px;
			display: inline-block;
			height: 100%;
			padding: 10px 25px;
			vertical-align: middle;
		}
		#double li  { width:50%;}
		#triple li  { width:33.333%; }
		#quad li    { width:25%; }
		#five li    { width:20%; }
		#six li     { width:16.666%; }
} 

/******Footer*******/
.footer-widgets {
	background-color:$gray-dark;
	color:$white;
}
.footer-bottom {
	background-color:$gray-darkest;
	color:$white;
	padding:5px 0;
		
		.footer-menu {
			text-align:righ;
			
			#menu-footer-navigation.navbar-nav {				
				a {
					padding:0 20px 0 0px;
					
					&hover {
						color:$orange;
						background:none;
						
				}
			}
		}
		.footer-text {
			text-align:left;
		}
	}
}
