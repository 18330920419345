/**************************************
main
**************************************/
@import url(https://fonts.googleapis.com/css?family=Open+Sans);

body {
	font-family: 'Open Sans', sans-serif;
	overflow-x: hidden;
}
.page-content {
	min-height:400px;
}

.grayscale {
    position: relative;
    background-size: 100% 100%;
    background-repeat: no-repeat;
		min-height: 100%;
}
.grayscale::after {
  content: "";
  background-size:cover;
  opacity: 0.5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;  
  -webkit-filter: grayscale(1); /* Old WebKit */
  filter: grayscale(1);
}
.align-right {
	text-align:right !important;
}
body {
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif !important;
}
.main-container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
}
.footer-bottom .nav>li>a:focus, .nav>li>a:hover {
    background-color: $black !important;
}
.alert-warning {
	background-color: $gray-lightest;
	border-color: $gray-light;
	color: $brand-highlight;
	font-weight:bold;
	font-size:20px;
}
		 
/**************************************
Global Gravity Views
**************************************/
.gv-table-view tfoot{ 
	display:none; 
}

.gv-datatables tfoot{ 
	display: none;
}

.gv-list-view-content p {
	margin: 0 0 0 0;
}
.gv-table-view th, .gv-table-view td {
	padding: .3em 0;
}
.standard-name, 
.basic-name{
	width: 45%;
}
.standard-address, 
.basic-address{
	width: 25%;
}
.standard-phone, 
.basic-phone{
	width: 15%;
}
.standard-link, 
.basic-link{
	width: 15%;
}
.top-table-content {
    margin-bottom: 0px;
}
.bottom-table-content {
    
}